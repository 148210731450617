<script setup lang='ts'>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { Roles } from '@/types/models/User';

const props = defineProps<{
  role: Roles,
}>();

const { t } = useI18n();

const roleLabel = computed(() => {
  if (props.role === Roles.admin) {
    return t('admin');
  }

  if (props.role === Roles.leadManager) {
    return t('role-lead-manager');
  }

  return t('manager');
});
</script>

<template>
  <div
    class="role"
    :class="{
      'role--admin': props.role === Roles.admin,
      'role--manager': props.role === Roles.user,
      'role--lead': props.role === Roles.leadManager,
    }"
  >
    {{ roleLabel }}
  </div>
</template>

<style lang="scss" scoped>
.role {
  @apply
    px-1.5 py-0.5
    rounded
    text-bgLight text-center
    bg-gray-400 dark:text-black;

  &--admin {
    @apply
      border border-sky-500 bg-sky-100 text-sky-500
      dark:border-sky-300 dark:bg-sky-300;
  }

  &--manager {
    @apply
      border border-primary bg-indigo-100 text-primary
      dark:border-indigo-200 dark:bg-indigo-300;
  }

  &--lead {
    @apply
      border border-purple-500 bg-purple-100 text-purple-500
      dark:border-purple-200 dark:bg-purple-300;
  }
}
</style>
