import { createApp } from 'vue';
import './assets/scss/app.scss';
import { createPinia } from 'pinia';
import Notifications from '@kyvg/vue3-notification';
import Oruga from '@oruga-ui/oruga-next';
import App from './App.vue';
import router from './routes';
import head from './utils/head';
import '@oruga-ui/oruga-next/dist/oruga.css';
import i18n from './utils/i18n';

createApp(App)
  .use(router)
  .use(createPinia())
  .use(head)
  .use(Notifications)
  .use(Oruga)
  .use(i18n)
  .mount('#app');
