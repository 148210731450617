import { useNotification } from '@kyvg/vue3-notification';

export const useNotify = () => {
  // eslint-disable-next-line
  const { notify } = useNotification();

  const notifyManyByObject = (notifications: object, type: string = 'error') => {
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(notifications)) {
      if (Array.isArray(value)) {
        value.forEach((message: any) => notify({
          title: message,
          type
        }));
      } else {
        notify({
          title: value,
          type
        });
      }
    }
  };

  return {
    notify,
    notifyManyByObject,
  };
};
