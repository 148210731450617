<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { onClickOutside } from '@vueuse/core';

const props = defineProps<{
  isOpen: boolean;
}>();

const emits = defineEmits<{
  (e: 'saved'): void,
  (e: 'close'): void,
}>();

const { t } = useI18n();

const modal = ref<HTMLDivElement>();

const close = () => emits('close');
onClickOutside(modal, () => close());

const handleKeydown = (e: KeyboardEvent) => {
  if (props.isOpen && e.key === 'Escape') {
    close();
  }
};

watch(() => props.isOpen, (newValue) => {
  if (newValue) {
    document.documentElement.style.overflow = 'hidden';
  } else {
    document.documentElement.style.overflow = 'auto';
  }
});

onMounted(() => {
  document.addEventListener('keydown', handleKeydown);
});

onUnmounted(() => {
  document.removeEventListener('keydown', handleKeydown);
});
</script>

<template>
  <Teleport to="#modals">
    <Transition name="slide-fade">
      <div
        v-if="isOpen"
        class="
          z-0
          fixed
          inset-0
          bg-black bg-opacity-70"
      >
        <div
          ref="modal"
          class="w-full max-w-lg p-3 relative mx-auto my-24  rounded-xl shadow-lg bg-bgLightBlue dark:bg-bgDarkSidebar bg-opacity-100"
        >
          <div>
            <div class="text-center p-3 flex-auto justify-center leading-6">
              <h2 class="main__title text-2xl font-bold py-4 text-bgDark">
                <slot name="header">
                  {{ t('title') }}
                </slot>
              </h2>

              <p class="text-md text-gray-500 px-8">
                <slot />
              </p>
            </div>
          </div>

          <div class="p-3 mt-2 text-center space-x-4 flex justify-center">
            <slot
              name="actions"
              :close="close"
            >
              <UButton
                class="tracking-wider md:mb-0 shadow-sm"
                @click="emits('saved')"
              >
                {{ t('save') }}
              </UButton>

              <UButton
                type="light"
                @click="close"
              >
                {{ t('cancel') }}
              </UButton>
            </slot>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.8s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
