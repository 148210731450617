export default {
  en: {
    'year': 'year',
    'by-day': 'By day',
    'by-month': 'By month',
    'search-criteria': 'You may need to change your search criteria',
    'manager': 'Manager',
    'managers': 'Managers',
    'admin': 'Admin',
    'lead-manager': 'Lead manager',
    'leadManagers': 'Lead Managers',
    'links': 'Links',
    'lead-group': 'Lead Group',
    'total-clicks': 'Total clicks',
    'unique-clicks': 'Unique clicks',
    'analytics': 'Analytics',
    'sure': 'Are you sure?',
    'cancel': 'Cancel',
    'deletion-date-pl': 'Deletion date',
    'new-group': 'New group',
    'wo-group': 'Without a group',
    'password': 'Password',
    'login': 'Login',
    'login-title': 'Login',
    'update': 'Change',
    'store': 'Creation',
    'destroy': 'Deletion',
    'restore': 'Restored',
    'my-groups': 'My groups',
    'link': 'Link',
    'date': 'Date',
    'soon': 'Soon',
    'at': 'at',
    'desktop': 'Desktop',
    'phone': 'Phone',
    'tablet': 'Tablet',
    'rest': 'Rest',
    'links-total': 'Total links with clicks',
    'clicks-total': 'Clicks total',
    'users-total': 'Users total',
    'error': 'Summary output error',
    'deletion': 'Deletion',
    'users': 'Users',
    'logout': 'Logout',
    'role-lead-manager': 'Lead',
    'logs': 'Logs',
    'save': 'Save',
    'newGroup': 'New group',
    'groupName': 'Group name',
    'title': 'Title',
    'other': 'Other',
  },
  ru: {
    'year': 'год',
    'by-day': 'По дням',
    'by-month': 'По месяцам',
    'search-criteria': 'Возможно, нужно поменять критерии поиска',
    'manager': 'Менеджер',
    'managers': 'Менеджеры',
    'admin': 'Админ',
    'lead-manager': 'Лид менеджер',
    'leadManagers': 'Лид менеджеры',
    'links': 'Ссылок',
    'lead-group': 'Лид Группа',
    'total-clicks': 'Кликов всего',
    'unique-clicks': 'Уникальные клики',
    'analytics': 'Аналитика',
    'sure': 'Вы уверены?',
    'cancel': 'Отменить',
    'deletion-date-pl': 'Дата удаления',
    'new-group': 'Новая группа',
    'wo-group': 'Без группы',
    'password': 'Пароль',
    'login': 'Войти',
    'login-title': 'Вход',
    'update': 'Изменение',
    'store': 'Создание',
    'destroy': 'Удаление',
    'restore': 'Восстановление',
    'my-groups': 'Мои группы',
    'link': 'Ссылка',
    'date': 'Дата',
    'soon': 'Скоро',
    'at': 'в',
    'desktop': 'Десктоп',
    'phone': 'Телефон',
    'tablet': 'Планшет',
    'rest': 'Остальное',
    'links-total': 'Всего ссылок с кликами',
    'clicks-total': 'Кликов всего',
    'users-total': 'Юзеров всего',
    'error': 'Ошибка вывода сводки',
    'deletion': 'Удаление',
    'users': 'Пользователи',
    'logout': 'Выйти',
    'role-lead-manager': 'Лид',
    'logs': 'Логи',
    'save': 'Сохранить',
    'newGroup': 'Новая группа',
    'groupName': 'Название группы',
    'title': 'Заголовок',
    'other': 'Другие',
  },
};
