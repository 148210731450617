export default {
  en: {
    'link-page': {
      'link-analytics': 'Link analytics',
      'total-clicks': 'Total clicks (does not change from the selected date)',
      'unique-clicks': 'Unique clicks',
      'unique-prev-clicks': 'Unique clicks for the previous period'
    },
    'user-page': {
      'page-title': 'Edit user'
    },
    dashboard: {
      stats: {
        'page-title': 'Analytics',
        'page-description': 'Statistics are updated every 24 hours',
        'filter-all-links': 'All links',
        'filter-my-links': 'My links',
        'filter-by-group-links': 'By groups',
        'group-select-placeholder': 'Select groups',
        'empty-stats-text': 'Stats not found',
        'empty-stats-subtext': 'You may need to change your search params'
      },
      settings: {
        'page-title': 'Account settings'
      },
    },
    leadGroups: {
      title: 'Lead groups',
    },
    'links-page': {
      title: 'Links',
    },
    'logs-page': {
      title: 'Logs',
    },
  },
  ru: {
    'link-page': {
      'link-analytics': 'Аналитика по ссылке',
      'total-clicks': 'Кликов всего (не изменяется от выбранной даты)',
      'unique-clicks': 'Уникальные клики',
      'unique-prev-clicks': 'Уникальные клики за предыдущий период'
    },
    'user-page': {
      'page-title': 'Редактирование пользователя'
    },
    dashboard: {
      stats: {
        'page-title': 'Аналитика',
        'page-description': 'Данные статистики обновляются раз в 24 часа',
        'filter-all-links': 'Все ссылки',
        'filter-my-links': 'Мои ссылки',
        'filter-by-group-links': 'По группам',
        'group-select-placeholder': 'Выберите группы',
        'empty-stats-text': 'Статистика отсутствует',
        'empty-stats-subtext': 'Возможно, нужно поменять значения фильтров'
      },
      settings: {
        'page-title': 'Настройки аккаунта'
      },
    },
    leadGroups: {
      title: 'Лид группы',
    },
    'links-page': {
      title: 'Ссылки',
    },
    'logs-page': {
      title: 'Логи',
    },
  },
};
