import auth from './middleware/auth';
import guest from './middleware/guest';

// @ts-ignore
export default function setupMeta(routes) {
  // @ts-ignore
  return routes.map((route) => {
    if(route.path.indexOf('dashboard') !== -1 || route.path === '/') {
      return {
        ...route,
        meta: {
          middleware: auth,
          layout: 'default'
        }
      };
    }

    return {
      ...route,
      meta: {
        middleware: guest,
        layout: 'auth'
      }
    };
  });
}
