<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { Option } from './types';

const props = defineProps<{
  modelValue?: string | object | number
  options?: Option[]
}>();

const emit = defineEmits(['update:modelValue']);

const selected = ref();
const selectRef = ref<HTMLDivElement>();
const isOpen = ref<boolean>(false);

const onSelect = (option: Option): void => {
  selected.value = option;
  emit('update:modelValue', option.value);
};

onMounted(() => {
  if (props.options && props.modelValue) {
    selected.value = props.options.find((option) => option.value === props.modelValue);
  }
  if (props.options && !props.modelValue) onSelect(props.options[0]);
});

onClickOutside(selectRef, () => {
  isOpen.value = false;
});
</script>

<template>
  <div
    class="select"
    :class="{'select--open': isOpen}"
  >
    <div
      ref="selectRef"
      class="select__header"
      @click.prevent="isOpen = !isOpen"
    >
      <slot name="header">
        <span>{{ selected?.name }}</span>
      </slot>

      <div class="select__icon">
        <UIcon icon="eva:arrow-ios-downward-fill" />
      </div>
    </div>

    <div class="select__body">
      <slot name="options">
        <button
          v-for="selectable in options"
          :key="selectable.id"
          type="button"
          class="select__item"
          @click.prevent="onSelect(selectable)"
        >
          {{ selectable.name }}
        </button>
      </slot>
    </div>
  </div>
</template>

<style lang="scss">
@import "./select.scss";
</style>
