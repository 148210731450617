const __pages_import_0__ = () => import("/src/pages/dashboard/users/index.vue");
const __pages_import_1__ = () => import("/src/pages/dashboard/stats/index.vue");
const __pages_import_2__ = () => import("/src/pages/dashboard/settings/index.vue");
const __pages_import_3__ = () => import("/src/pages/dashboard/logs/index.vue");
const __pages_import_4__ = () => import("/src/pages/dashboard/link.vue");
const __pages_import_5__ = () => import("/src/pages/dashboard/link/[id].vue");
const __pages_import_6__ = () => import("/src/pages/dashboard/lead-groups.vue");
const __pages_import_7__ = () => import("/src/pages/dashboard/lead-groups/index.vue");
const __pages_import_8__ = () => import("/src/pages/dashboard/lead-groups/[id].vue");
const __pages_import_9__ = () => import("/src/pages/dashboard/lead-groups/[id]/index.vue");
const __pages_import_10__ = () => import("/src/pages/dashboard/lead-groups/[id]/user/[user_id].vue");
const __pages_import_11__ = () => import("/src/pages/dashboard/lead-groups/[id]/user/index.vue");
const __pages_import_12__ = () => import("/src/pages/dashboard/index.vue");
const __pages_import_13__ = () => import("/src/pages/login.vue");
const __pages_import_14__ = () => import("/src/pages/dashboard/update-user/[id].vue");

const routes = [{"name":"dashboard-users","path":"/dashboard/users","component":__pages_import_0__,"props":true},{"name":"dashboard-stats","path":"/dashboard/stats","component":__pages_import_1__,"props":true},{"name":"dashboard-settings","path":"/dashboard/settings","component":__pages_import_2__,"props":true},{"name":"dashboard-logs","path":"/dashboard/logs","component":__pages_import_3__,"props":true},{"name":"dashboard-link","path":"/dashboard/link","component":__pages_import_4__,"children":[{"name":"dashboard-link-id","path":":id","component":__pages_import_5__,"props":true}],"props":true},{"path":"/dashboard/lead-groups","component":__pages_import_6__,"children":[{"name":"dashboard-lead-groups","path":"","component":__pages_import_7__,"props":true},{"path":":id","component":__pages_import_8__,"children":[{"name":"dashboard-lead-groups-id","path":"","component":__pages_import_9__,"props":true},{"name":"dashboard-lead-groups-id-user-user_id","path":"user/:user_id","component":__pages_import_10__,"props":true},{"name":"dashboard-lead-groups-id-user","path":"user","component":__pages_import_11__,"props":true}],"props":true}],"props":true},{"name":"dashboard","path":"/dashboard","component":__pages_import_12__,"props":true},{"name":"login","path":"/login","component":__pages_import_13__,"props":true},{"name":"dashboard-update-user-id","path":"/dashboard/update-user/:id","component":__pages_import_14__,"props":true}];

export default routes;