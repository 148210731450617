import Cookie from 'js-cookie';
import useUserStore from '@/store/user';

export const useAuth = () => {
  const login = async (token: string) => {
    Cookie.set('token_session', token, {
      expires: 365,
    });

    await useUserStore().fetchMe();
  };

  /**
   * Выход из аккаунта
   */
  const logout = () => {
    Cookie.remove('token_session');
  };

  return {
    login,
    logout,
  };
};
