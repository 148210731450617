<script setup lang="ts">
import { ref } from 'vue';
import useThemeStore from '@/store/theme';

const THEME_SWITCH_TIMEOUT = 500;
const LOADING_TIMEOUT = 1000;

const theme = useThemeStore();
const isLoading = ref(false);

const toggleTheme = () => {
  isLoading.value = true;

  setTimeout(() => {
    theme.toggleTheme();
  }, THEME_SWITCH_TIMEOUT);

  setTimeout(() => {
    isLoading.value = false;
  }, LOADING_TIMEOUT);
};
</script>

<template>
  <Transition name="fade">
    <AppLoader
      v-if="isLoading"
      class="z-50"
    />
  </Transition>

  <div
    v-if="!isLoading"
    class="theme"
  >
    <button
      id="button-language"
      class="theme__button"
      @click="toggleTheme"
    >
      <UIcon
        v-if="theme.isDark"
        icon="eva:sun-outline"
      />

      <UIcon
        v-else
        icon="eva:moon-outline"
      />
    </button>
  </div>
</template>

<style lang="scss">
@import "./theme.scss";
</style>
