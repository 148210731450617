export default {
  en: {
    navbar: {
      'home': 'Home',
      'analytics': 'Analytics',
      'logs': 'Logs',
      'users': 'Users',
      'groups': 'Lead groups'
    },
    chart: {
      'dynamics': 'Click-through dynamics by countries for {year} year',
      'dynamics-year': 'Dynamics of clicks for {year} year',
      'not-found': 'Info not found',
      'select-placeholder': 'Country search',
      lead: {
        'dynamics': 'Click-through dynamics',
      },
    },
    lead: {
      group: {
        'total-clicks': 'Total clicks',
        'unique-clicks': 'Unique clicks',
        'links-count': 'Links',
        'not-found': 'Managers not found'
      },
      form: {
        'addGroup': 'Create group',
        'total-managers': 'Total managers',
        'leads': 'Leads',
        'count-label': 'Total number:',
        'not-found': 'Lead groups not found',
        'actions': 'Actions',
        'soon': 'Soon',
        'group': 'Lead Group',
        'sure': 'Are you sure you want to delete the lead group?'
      },
    },
    'link-component': {
      main: {
        'search': 'Link search',
        'full-link': 'Full link',
        'delete-date': 'Link delete date',
        'manager-deleted': 'Deleted',
        'clicks': 'Clicks',
        'actions': 'Actions',
        'links-404': 'Links not found',
        'archived': 'archived'
      },
      'delete-links': 'Delete all related links as well?',
      'will-delete': 'Everything related to these links will be deleted (except logs)',
      'delete': 'Yes, delete it',
      'add-link': 'Add new link',
      'deletion-date': 'Set deletion date',
      'deletion-date-pl': 'Deletion date',
      'tag-or-link': 'tag or link',
      'use-cloak': 'Use cloak',
      'safe-connection': 'Safe connection',
      'bots-link': 'Link for bots',
      'create-link': 'Create link'
    },
    'logs-component': {
      filters: {
        'description': 'Filters',
        'search-placeholder': 'Search by links',
        'target-all': 'Actions on all objects',
        'target-user': 'Actions on users',
        'target-lead-group': 'Actions on lead groups',
        'target-links': 'Actions on links',
        'event-all': 'All actions',
        'event-store': 'Creation',
        'event-destroy': 'Deletion',
        'event-many-destroy': 'Many deletion',
        'event-restore': 'Restored',
        'event-update': 'Update',
      },
      'not-found': 'Sorry, logs not found'
    },
    'users-component': {
      main: {
        'add-account': 'Add account',
        'filter-title': 'User search',
        'filter-archive': 'Show archived users',
        'filter-search-placeholder': 'User name',
        'filter-role-all': 'All roles',
        'filter-role-user': 'Managers',
        'filter-role-lead': 'Lead managers',
        'filter-role-admin': 'Admins',
        'table-empty-message': 'Not found users',
        'table-search-params': 'You may need to change your search params',
        'table-col-name': 'Name',
        'table-col-role': 'Role',
        'table-col-links': 'Links count'
      },
      form: {
        'required-rule': 'Required field',
        'email-rule': 'Enter correct email',
        'length-rule': 'At least {length} characters',
        'name': 'Name',
        'role': 'Role',
        'email': 'Email',
        'password': 'Password',
        'submit': 'Add',
        'role-manager': 'Manager',
        'role-lead': 'Lead manager',
        'fieldset-info': 'Specify the name and role of the new user',
        'fieldset-credentials': 'Auth credentials'
      },
      update: {
        'user-data': 'User',
        'change-password': 'Change password',
        'acc-name': 'Account name',
        'role-user': 'Manager',
        'avatar-size-rule': 'max {size} kb',
        'role-lead': 'Lead manager',
        'old-password': 'Old password',
        'new-password': 'New password',
        'confirm-password': 'Confirm password',
        'close-sessions': 'Close all sessions',
        'required-rule': 'Required field',
        'length-rule': 'At least {length} characters',
        'submit': 'Save'
      },
    },
    'analytics-component': {
      'last-month-clicks': 'Clicks in the last month'
    },
    archive: {
      'will-delete': 'The user will be archived',
      'delete': 'Archive'
    },
    'logout-component': {
      'sure': 'Are you sure you want to log out?',
      'account-settings': 'Account settings',
    },
    'restore-component': {
      'sure': 'Are you sure?',
      'will-delete': 'The user will be restored',
      'delete': 'Restore'
    },
    calendar: {
      'date': 'Date',
      'at': 'at',
      'all-time': 'All time',
      'from-to': 'From {start} to {end}',
      'today': 'Today',
      'last-week': 'Last\u00a0week',
      'last-month': 'Last\u00a0month'
    },
    pie: {
      'empty-stats-text': 'Stats not found',
      'empty-stats-subtext': 'You may need to change your filter params'
    },
  },
  ru: {
    navbar: {
      'home': 'Главная',
      'analytics': 'Аналитика',
      'logs': 'Логи',
      'users': 'Пользователи',
      'groups': 'Лид группы'
    },
    chart: {
      'dynamics': 'Динамика переходов по странам за {year} год',
      'dynamics-year': 'Динамика переходов за {year} год',
      'not-found': 'Информации нет',
      'select-placeholder': 'Поиск страны',
      lead: {
        'dynamics': 'Динамика переходов',
      },
    },
    lead: {
      group: {
        'total-clicks': 'Кликов всего',
        'unique-clicks': 'Уникальные клики',
        'links-count': 'Ссылки',
        'not-found': 'Менеджеры не найдены'
      },
      form: {
        'addGroup': 'Добавить группу',
        'total-managers': 'Количество менеджеров',
        'leads': 'Лиды',
        'count-label': 'Общее число:',
        'not-found': 'Лид группы не найдены',
        'actions': 'Действия',
        'soon': 'Скоро',
        'group': 'Лид Группа',
        'sure': 'Вы уверены, что хотите удалить лид группу?'
      },
    },
    'link-component': {
      main: {
        'search': 'Поиск ссылок',
        'full-link': 'Полная ссылка',
        'delete-date': 'Удаление ссылки',
        'manager-deleted': 'Удалён',
        'clicks': 'Кликов',
        'actions': 'Действия',
        'links-404': 'Ссылок нет',
        'archived': 'архивирован'
      },
      'delete-links': 'Удалить также все связанные ссылки?',
      'add-link': 'Добавить новую ссылку',
      'deletion-date': 'Установить дату удаления',
      'deletion-date-pl': 'Дата удаления',
      'tag-or-link': 'тег или ссылка',
      'use-cloak': 'Включить клоаку',
      'safe-connection': 'Безопасное соединение',
      'bots-link': 'Ссылка для ботов',
      'create-link': 'Создать ссылку',
      'will-delete': 'Удалится всё, что связано с этими ссылками (кроме логов)',
      'delete': 'Да, удалить'
    },
    'logs-component': {
      filters: {
        'description': 'Фильтры',
        'search-placeholder': 'Поиск по ссылке',
        'target-all': 'Действия над всеми обьектами',
        'target-user': 'Действия над пользователями',
        'target-lead-group': 'Действия над лид группами',
        'target-links': 'Действия над лид ссылками',
        'event-all': 'Все действия',
        'event-store': 'Создание',
        'event-destroy': 'Удаление',
        'event-many-destroy': 'Массовое удаление',
        'event-restore': 'Восстановление',
        'event-update': 'Изменение',
      },
      'not-found': 'Логи не найдены'
    },
    'users-component': {
      main: {
        'add-account': 'Добавить аккаунт',
        'filter-title': 'Поиск пользователя',
        'filter-archive': 'Показать архивированных пользователей',
        'filter-search-placeholder': 'Имя пользователя',
        'filter-role-all': 'Все должности',
        'filter-role-user': 'Менеджеры',
        'filter-role-lead': 'Лид менеджеры',
        'filter-role-admin': 'Администраторы',
        'table-empty-message': 'Пользователи не найдены',
        'table-search-params': 'Возможно, нужно поменять критерии поиска',
        'table-col-name': 'Имя',
        'table-col-role': 'Должность',
        'table-col-links': 'Количество ссылок'
      },
      form: {
        'required-rule': 'Обязательное поле',
        'email-rule': 'Введите корректную почту',
        'length-rule': 'Минимум {length} знаков',
        'name': 'Имя',
        'role': 'Роль',
        'email': 'Почта',
        'password': 'Пароль',
        'submit': 'Добавить',
        'role-manager': 'Менеджер',
        'role-lead': 'Лид менеджер',
        'fieldset-info': 'Укажите имя и роль',
        'fieldset-credentials': 'Данные для авторизации'
      },
      update: {
        'user-data': 'Пользователь',
        'change-password': 'Изменение пароля',
        'acc-name': 'Имя аккаунта',
        'role-user': 'Менеджер',
        'avatar-size-rule': 'макс {size} кб',
        'role-lead': 'Лид мененджер',
        'old-password': 'Старый пароль',
        'new-password': 'Новый пароль',
        'confirm-password': 'Подтвердите пароль',
        'close-sessions': 'Закрыть все сессии',
        'required-rule': 'Обязательное поле',
        'length-rule': 'Минимум {length} знаков',
        'submit': 'Сохранить'
      },
    },
    'analytics-component': {
      'last-month-clicks': 'Кликов за последний месяц'
    },
    archive: {
      'will-delete': 'Пользователь будет архивирован',
      'delete': 'Архивировать'
    },
    'logout-component': {
      'sure': 'Вы уверены, что хотите выйти из аккаунта?',
      'account-settings': 'Настройки аккаунта',
    },
    'restore-component': {
      'sure': 'Вы уверены?',
      'will-delete': 'Пользователь будет восстановлен',
      'delete': 'Восстановить',
    },
    calendar: {
      'date': 'Дата',
      'at': 'в',
      'all-time': 'Все время',
      'from-to': 'С {start} по {end}',
      'today': 'Сегодня',
      'last-week': 'За\u00a0неделю',
      'last-month': 'За\u00a0месяц'
    },
    pie: {
      'empty-stats-text': 'Статистика не найдена',
      'empty-stats-subtext': 'Попробуйте поменять параметры фильтров'
    },
  },
};
