import { defineStore } from 'pinia';
import { State } from '@/store/groups/types';
import { useAxios } from '@/composables/useAxios';
import useUserStore from '@/store/user';
import { useNotify } from '@/composables/useNotify/useNotify';

const { notify } = useNotify();

const useGroupStore = defineStore('groups', {
  state: (): State => ({
    groups: [],
  }),

  actions: {
    // @ts-ignore
    async save(group) {
      const $axios = useAxios();

      $axios.post('/api/link-groups', group)
        .then(({ data }) => {
          this.groups.unshift(data.data);
        });
    },

    remove(id: number) {
      const indexRemoved = this.groups.findIndex((g) => g.id === id);
      this.groups.splice(indexRemoved, 1);
    },

    delete(id: number, isCascade: boolean) {
      const $axios = useAxios();

      const endpoint = isCascade ? `/api/link-groups/${ id }?cascade=1` : `/api/link-groups/${ id }`;

      return new Promise((resolve, reject) => {
        $axios.delete(endpoint)
          .then(({ data }) => {
            notify({
              title: data.message,
              type: 'success'
            });
            this.remove(id);
            resolve(data);
          })
          .catch((err) => {
            notify({
              title: err.response.data.message,
              type: 'error'
            });
            reject(err);
          });
      });
    },

    async fetchGroup() {
      const userStore = useUserStore();
      const $axios = useAxios();

      return new Promise((resolve, reject) => {
        // @ts-ignore
        $axios.get(`/api/link-groups?user_id=${ userStore.getUser.id }`)
          .then(({ data }) => {
            this.groups = data.data;
            resolve(data);
          })
          .catch((err) => {
            notify({
              title: err.response.data.message,
              type: 'error'
            });
            reject(err);
          });
      });
    },
  },

  getters: {
    getGroups: (state: State) => state.groups,
  },
});

export default useGroupStore;
