import { createI18n } from 'vue-i18n';
import { useLang } from '@/composables/useLang/useLang';
import { messages } from '@/locales';

const { getLang } = useLang();

const i18n = createI18n({
  locale: getLang(),
  legacy: false,
  fallbackLocale: 'ru',
  messages
});

export default i18n;
