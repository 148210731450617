import { createRouter, createWebHistory } from 'vue-router';
import routes from '~pages';
import setupMeta from './meta';
import nextFactory from './next-factory';

const router = createRouter({
  history: createWebHistory(),
  routes: setupMeta(routes)
});

// Кастомные middleware'ы
router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };

    const nextMiddleware = nextFactory(context, middleware, 1);
    
    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    return next({ name: 'dashboard' });
  }

  if (to.name === 'dashboard-link') {
    return next({ name: 'dashboard' });
  }

  return next();
});

export default router;
