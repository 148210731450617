<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import useUserStore from '@/store/user';
import { Roles } from '@/types/models/User';

const { t } = useI18n();

const userStore = useUserStore();
const user = computed(() => userStore.user);

const userRoutes = computed(() => {
  // маршруты доступные всем пользователям
  const sharableRoutes = [
    { name: 'dashboard', icon: 'eva:compass-outline', label: t('navbar.home') },
    { name: 'dashboard-stats', icon: 'eva:pie-chart-outline', label: t('navbar.analytics') },
    { name: 'dashboard-logs', icon: 'eva:archive-outline', label: t('navbar.logs') },
  ];

  const adminRoutes = [
    { name: 'dashboard-lead-groups', icon: 'eva:grid-outline', label: t('navbar.groups') },
    { name: 'dashboard-users', icon: 'eva:people-outline', label: t('navbar.users') },
  ];

  switch (user.value?.role) {
    case Roles.user:
      return sharableRoutes;
    case Roles.leadManager:
      return [
        ...sharableRoutes,
      ];
    case Roles.admin:
      return [
        ...sharableRoutes,
        ...adminRoutes,
      ];
    default:
      // не создаем роуты если роль пользователя не определена
      return [];
  }
});

const navbar = ref({
  isCollapsed: false,
  setCollapsed() {
    this.isCollapsed = ! this.isCollapsed;
  }
});
</script>

<template>
  <div
    class="navbar"
    :class="{'navbar--collapsed': navbar.isCollapsed}"
  >
    <div class="navbar__logo">
      <AppLogo />
    </div>

    <ul class="navbar__navigation navigation">
      <li
        v-for="route in userRoutes"
        :key="route.name"
        class="navigation__item"
      >
        <RouterLink
          :to="{ name: route.name }"
          class="navigation__link link"
          :class="{'link--collapsed': navbar.isCollapsed}"
          active-class="link--active"
        >
          <UIcon
            class="link__icon"
            :icon="route.icon"
          />
          <span class="link__text">
            {{ route.label }}
          </span>
        </RouterLink>
      </li>
    </ul>

    <button
      class="navbar__toggler toggler"
      :class="{'toggler--collapsed': navbar.isCollapsed}"
      @click="navbar.setCollapsed"
    />
  </div>
</template>

<style lang="scss" scoped>
@import "navbar";
</style>
