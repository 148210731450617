import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { useI18n } from 'vue-i18n';

export const useDayjs = () => {
  const { locale } = useI18n();
  dayjs.locale(locale.value);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault('Europe/Moscow');

  return dayjs;
};
