import { useLocalStorage } from '@/composables/useLocalStorage/useLocalStorage';
import Router from '@/routes';
import Lang from './types';

const STORAGE_KEY = 'i18nextLng';

export const useLang = () => {
  const setLang = async (lang: Lang) => {
    const storage = useLocalStorage();

    storage.setItemInLocalStorage(STORAGE_KEY, lang);
    Router.go(0); // reload page
  };

  const getLang = () => {
    const storage = useLocalStorage();
    const storageLanguage = storage.getItemFromLocalStorage(STORAGE_KEY);

    if (!storageLanguage) {
      const browserLang = navigator.language?.slice(0, 2);

      return browserLang !== Lang.ru ? Lang.en : Lang.ru;
    }

    if ([Lang.en, Lang.ru].includes(storageLanguage)) {
      return storageLanguage;
    }

    return Lang.en;
  };

  return {
    setLang,
    getLang,
  };
};
