<template>
  <RouterLink
    :to="{ name: 'dashboard' }"
    class="logo"
  >
    Cropped
  </RouterLink>
</template>

<style lang="scss" scoped>
.logo {
  font-weight: 700;
  line-height: 1;
  writing-mode: vertical-rl;
  white-space: nowrap;
  display: inline-block;
  overflow: visible;
  transform: rotate(180deg);

  @apply text-white text-2xl;
}
</style>
