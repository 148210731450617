import common from './common';
import components from './components';
import pages from './pages';

export const messages = {
  en: {
    ...pages.en,
    ...common.en,
    ...components.en,
  },
  ru: {
    ...pages.ru,
    ...common.ru,
    ...components.ru,
  }
};
