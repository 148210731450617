<script setup lang="ts">
import Hammer from 'hammerjs';
import { onMounted, ref } from 'vue';
import useGroupStore from '@/store/groups';

const $groups = useGroupStore();
onMounted( async () => {
  await $groups.fetchGroup();
});

const mc = new Hammer(document.body);
const swipeSidebar = ref();
const isActive = ref(false);

mc.on('swipeleft swiperight', (ev) => {
  if(ev.type === 'swipeleft') {
    isActive.value = true;
  } else {
    isActive.value = false;
  }
});
</script>

<template>
  <section
    ref="swipeSidebar"
    class="sidebar"
    :class="{'sidebar--active': isActive}"
  >
    <div class="sidebar__header">
      <AppTheme />
      <AppLang />
      <UserMenu />
    </div>

    <div class="sidebar__content sidebar-content">
      <LinkCreate />
      <MainGroups />
    </div>
  </section>
</template>

<style lang="scss" scoped>
@import "./sidebar.scss";
</style>
