import { defineStore } from 'pinia';
import { useAxios } from '@/composables/useAxios';
import { removeEmpties } from '@/helpers/clear-empties';
import { useNotify } from '@/composables/useNotify/useNotify';
import { State, CreateFormParams } from '@/store/links/types';
import { Link } from '@/types/models/Link';

const { notify } = useNotify();

const useLinksStore = defineStore('links', {
  state: (): State => ({
    data: {
      total: 0,
      links: [],
    },
    pending: true,
    page: 1,
    limit: 15,
    checkedLinks: [],
    search: '',
    linkGroupId: null,
    userId: [],
    orderField: 'created_at',
    orderType: 'desc',
    range: {
      start: null,
      end: null,
    }
  }),
  actions: {
    save(payload: CreateFormParams): Promise<{ data: Link }> {
      const $axios = useAxios();

      return new Promise((resolve, reject) => {
        $axios.post('/api/links', removeEmpties(payload))
          .then(({ data }) => {
            this.data.links.unshift(data.data);
            resolve(data);
          })
          .catch(({ response }) => {
            notify({
              title: response.data.message,
              type: 'error'
            });
            reject(response);
          });
      });
    },

    async fetchLinks(page: number) {
      const $axios = useAxios();
      this.pending = true;
      this.checkedLinks = [];

      const query = {
        page,
        limit: this.limit,
        search: this.search,
        user_id: this.userId.join(','),
        link_group_id: this.linkGroupId,
        order: `${ this.orderField },${ this.orderType }`,
        date_from: this.range.start,
        date_to: this.range.end,
      };

      await $axios.get('/api/links', { params: removeEmpties(query) })
        .then(({ data }) => {
          this.data = data.data;
        })
        .finally(() => {
          this.pending = false;
        });
    },

    setPage(page: number) {
      this.page = page;
    },

    async delete(id: number) {
      const $axios = useAxios();

      await $axios.delete(`/api/links/${ id }`)
        .then(({ data }) => {
          notify({
            title: data.message,
            type: 'success'
          });

          this.data.links.splice(
            this.data.links.findIndex((link) => link.id === id),
            1
          );
        })
        .catch(({ response }) => {
          notify({
            title: response.data.message,
            type: 'error'
          });
        });
    },

    async bulkDelete() {
      const $axios = useAxios();

      await $axios.delete(`/api/delete-links?ids=${ this.checkedLinks.map((link) => link.id).join(',') }`)
        .then(({ data }) => {
          notify({
            title: data.message,
            type: 'success'
          });

          this.data.links = this.data.links.filter((link) => !this.checkedLinks.some((l) => l.id === link.id));

          this.checkedLinks = [];
          this.fetchLinks(this.page);
        })
        .catch(({ response }) => {
          notify({
            title: response.data.message,
            type: 'error'
          });
        });
    }
  },

  getters: {
    allLinks({ data }: State) {
      return data.links;
    },
  },
});

export default useLinksStore;
