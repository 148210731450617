<script setup lang="ts">
interface LinkGroup {
  id: number;
  name: string;
  user_id: number;
  links_count: number;
}
const props = defineProps<LinkGroup>();
</script>

<template>
  <li class="groups-link__item">
    <span class="groups-link__text">
      {{ name }} <span class="text-grey">{{ links_count }}</span>
    </span>

    <LinkGroupDelete v-bind="props" />
  </li>
</template>
