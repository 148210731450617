import axios from 'axios';
import Cookies from 'js-cookie';
import i18n from '@/utils/i18n';

interface AxiosOptions {
  baseURL: string
  mode: 'no-cors'
  headers?: {
    Authorization?: string
    'Content-Language': string
  }
}

export const useAxios = (options?: AxiosOptions | null, isNotBearer?: boolean) => {
  const { locale } = i18n.global;

  let defaults: AxiosOptions = {
    baseURL: import.meta.env.VITE_BASE_URL_API,
    mode: 'no-cors'
  };
  
  if (!isNotBearer) {
    const cookie = Cookies.get('token_session');

    defaults = {
      ...defaults,
      headers: {
        Authorization:  `Bearer ${ cookie }`,
        'Content-Language': locale.value
      }
    };
  }

  return axios.create({
    ...defaults,
    ...options
  });
};
