<script lang="ts" setup>
type _VTI_TYPE_Roles = /* enum */ string
interface _VTI_TYPE_User {
  id: number,
  name: string,
  avatar: string | null,
  email: string,
  role: _VTI_TYPE_Roles,
  created_at: string,
  updated_at: string
  deleted_at: null | string;
  links_count: number;
}
interface AvatarProps {
  user?: _VTI_TYPE_User;
  size?: 'xxs'|'small'|'large'|'default';
}
import { computed } from 'vue'
import useUserStore from '@/store/user'
const props = withDefaults(defineProps<AvatarProps>(), {
  size: 'default',
});
const userStore = useUserStore();
const user = computed(() => {
  if (!props.user) {
    return userStore.user;
  }
  return props.user;
});
const currentAvatar = computed(() => {
  if (!user.value?.avatar) {
    const names = [
      'Misty',
      'Patches',
      'Tiger',
      'Tinkerbell',
      'Rocky',
      'Tigger',
      'Fluffy',
      'Scooter',
      'Jasmine',
      'Precious',
      'Midnight'
    ];
    let index = Math.floor(Math.random() * (names.length - 1 + 1) + 1);
    if (user.value?.email) {
      const firstChar = user.value.email[0];
      const pattern = {
        '[A-Ca-c]': 0,
        '[D-Fd-f]': 1,
        '[G-Ig-i]': 2,
        '[J-Lj-l]': 3,
        '[M-Om-o]': 4,
        '[P-Rp-r]': 5,
        '[S-Ts-t]': 6,
        '[U-Vu-v]': 7,
        '[V-Xv-x]': 8,
        '[Y-Zy-z]': 9,
      };
      Object.entries(pattern).forEach(([regexp, number]) => {
        if (firstChar.match(regexp)) {
          index = number;
        }
      });
    }
    return `https://api.dicebear.com/7.x/notionists/svg?seed=${ names[index] }`;
  }
  return user.value.avatar;
});
const archived = computed(() => Boolean(props.user?.deleted_at));
</script>

<template>
  <span class="avatar-wrapper">
    <img
      :src="currentAvatar"
      :alt="user?.name"
      class="user-avatar"
      :class="`user-avatar--${ props.size }`"
    >
    <UIcon
      v-if="archived"
      class="avatar-icon"
      icon="eva:trash-2-outline"
    />
  </span>
</template>

<style lang="scss" scoped>
@import "./user-avatar.scss";
</style>
