export enum Roles {
  user = 'user',
  admin = 'admin',
  leadManager = 'lead_manager'
}

export interface User {
  id: number,
  name: string,
  avatar: string | null,
  email: string,
  role: Roles,
  created_at: string,
  updated_at: string
  deleted_at: null | string;
  links_count: number;
}
