<template>
  <div class="loader">
    <span class="loader__el">
      <span>Cropped</span>
    </span>
  </div>
</template>

<style lang="scss" scoped>
@import "./loader.scss";
</style>
