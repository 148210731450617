<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import useGroupStore from '@/store/groups';

const { t } = useI18n();
const groupStore = useGroupStore();

const getGroups = computed(() => groupStore.getGroups);
</script>

<template>
  <div class="groups-link">
    <h2 class="groups-link__title">
      {{ t('my-groups') }}
    </h2>

    <div class="groups-link__content">
      <LinkGroupCreate />

      <ul class="groups-link__list">
        <LinkGroupItem
          v-for="group in getGroups"
          :key="group.id"
          v-bind="group"
        />
      </ul>
    </div>
  </div>
</template>
