<script setup lang="ts">
import { ref, watch, shallowRef, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Default from './layouts/default.vue';
import Auth from './layouts/auth.vue';
import useUserStore from './store/user';

const $route = useRoute();
const layout = shallowRef();
const isLoading = ref<boolean>(true);

watch($route, async () => {
  if($route.meta.layout === 'auth') layout.value = Auth;
  else layout.value = Default;
});

onMounted(() => {
  const { push } = useRouter();
  const { fetchMe } = useUserStore();

  fetchMe()
    .then(() => {
      if($route.name === 'login') {
        push({ name: 'dashboard' });
      }
    })
    .catch(() => {
      push({ name: 'login' });
    })
    .finally(() => {
      isLoading.value = false;
    });
});
</script>

<template>
  <div class="dark:bg-bgDark dark:text-white">
    <Transition name="fade">
      <AppLoader v-if="isLoading" />
    </Transition>

    <Transition name="fade">
      <component
        :is="layout"
        v-if="!isLoading"
      >
        <RouterView />
      </component>
    </Transition>

    <notifications position="bottom right" />
  </div>
</template>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
