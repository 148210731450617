<script setup lang="ts">
interface PropTypes {
  id: number;
  name: string;
  user_id: number;
  links_count: number;
}
import useGroupStore from '@/store/groups'
import useLinksStore from '@/store/links'
import { onClickOutside } from '@vueuse/core'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n();
const props = defineProps<PropTypes>();
const isOpen = ref<boolean>(false);
const target = ref<HTMLDivElement>();
onClickOutside(target, () => {
  isOpen.value = false;
});
const isCascade = ref<boolean>(true);
const groups = useGroupStore();
const links = useLinksStore();
const pending = ref<boolean>(false);
const onDelete = () => {
  pending.value = true;
  groups.delete(props.id, isCascade.value)
    .then(() => {
      isOpen.value = false;
      links.fetchLinks(links.page);
    })
    .finally(() => {
      pending.value = false;
    });
};
</script>

<template>
  <div
    ref="target"
  >
    <button
      class="groups-link__button-delete"
      @click="isOpen = !isOpen"
    >
      <UIcon icon="eva:trash-2-outline" />
    </button>

    <Transition name="fadeInUp">
      <div
        v-show="isOpen"
        class="
          absolute
          top-full
          left-0
          right-0
          z-10
          shadow-xl
          bg-white
          w-full
          px-2
          py-3
          rounded-lg
          -translate-y-1
          dark:bg-bgDarkSidebar
          dark:border
          dark:border-gray-700
        "
      >
        <h5 class="text-md font-semibold">
          {{ t('sure') }}
        </h5>
        <h6 class="text-xs text-grey">
          {{ t('link-component.will-delete') }}
        </h6>

        <OCheckbox
          v-model="isCascade"
          class="text-xs mt-4"
        >
          {{ t('link-component.delete-links') }}
        </OCheckbox>

        <div class="flex justify-end gap-1 mt-3">
          <UButton
            type="light"
            size="xs"
            :loading="pending"
            @click="isOpen = false"
          >
            {{ t('cancel') }}
          </UButton>

          <UButton
            type="danger"
            size="xs"
            :loading="pending"
            @click="onDelete"
          >
            {{ t('link-component.delete') }}
          </UButton>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.fadeInUp-enter-active,
.fadeInUp-leave-active {
  transition: all 150ms ease-out;
}

.fadeInUp-enter-from,
.fadeInUp-leave-to {
  transform: translateY(-15px);
  opacity: 0;
}
</style>
