<script setup lang="ts">
import {
  computed,
  reactive,
  ref,
  watch,
} from 'vue';
import useLinksStore from '@/store/links';
import { useI18n } from 'vue-i18n';
import { useCopy } from '@/composables/useCopy/useCopy';

const { t } = useI18n();
const { copy } = useCopy();

interface FormState {
  isLoading: boolean;
  data: {
    short_name: string;
    link: string;
    cloaca_link: string;
    delete_in: null | string;
    link_group_id: null | string;
    is_safe: boolean;
  },
}

const form = reactive<FormState>({
  isLoading: false,
  data: {
    short_name: '',
    link: '',
    cloaca_link: '',
    delete_in: null,
    link_group_id: null,
    is_safe: false,
  },
});

const shortNameKey = ref(false);

const isInvalid = computed(() => !(form.data.short_name.length > 1 && form.data.link.length > 1));

const hasDeleteIn = ref<boolean>(false);
watch(hasDeleteIn, () => {
  if(!hasDeleteIn.value) form.data.delete_in = null;
});

const hasCloaca = ref<boolean>(false);
watch(hasCloaca, () => {
  if(!hasCloaca.value) form.data.cloaca_link = '';
});

const hasSafe = ref<boolean>(false);
watch(hasSafe, () => {
  if(!hasSafe.value) form.data.is_safe = false;
});

function clearForm() {
  form.isLoading = false;
  form.data.short_name = '';
  form.data.link = '';
  form.data.cloaca_link = '';
  form.data.is_safe = false;
  form.data.delete_in = null;
  form.data.link_group_id = null;

  // опциональные данные
  hasCloaca.value = false;
  hasDeleteIn.value = false;
  hasSafe.value = false;
}

const links = useLinksStore();

const handleSubmit = () => {
  if (isInvalid.value) return;

  form.isLoading = true;

  links.save(form.data)
    .then((res) => {
      copy(res?.data?.short_name);
      clearForm();
    })
    .finally(() => {
      form.isLoading = false;
      shortNameKey.value = !shortNameKey.value;
    });
};
</script>

<template>
  <form
    class="form-create"
    @submit.prevent="handleSubmit"
  >
    <h2 class="form-create__title">
      {{ t('link-component.add-link') }}
    </h2>

    <UInput
      v-model="form.data.link"
      :placeholder="t('link-component.tag-or-link')"
    />

    <UInput
      :key="shortNameKey"
      v-model="form.data.short_name"
      placeholder="-----"
      before-text="https://cropped.link/"
      :generate-text="true"
      :generate-text-onmount="true"
    />

    <LinkGroupList
      v-model="form.data.link_group_id"
      class="!mb-4"
    />

    <BtnContentToggle
      v-model="hasDeleteIn"
      class="mb-[10px]"
    >
      {{ t('link-component.deletion-date') }}

      <template #content>
        <UCalendar
          v-model="form.data.delete_in"
          :min-date="new Date()"
          mode="dateTime"
          :placeholder="t('link-component.deletion-date-pl')"
        />
      </template>
    </BtnContentToggle>

    <BtnContentToggle
      v-model="hasCloaca"
      class="mb-[10px]"
    >
      {{ t('link-component.use-cloak') }}

      <template #content>
        <UInput
          v-model="form.data.cloaca_link"
          :placeholder="t('link-component.bots-link')"
          class="!mb-0"
        />
      </template>
    </BtnContentToggle>

    <BtnContentToggle v-model="hasSafe">
      {{ t('link-component.safe-connection') }}

      <template #content>
        <OCheckbox
          v-model="form.data.is_safe"
          class="mt-2"
        >
          {{ t('link-component.safe-connection') }}
        </OCheckbox>
      </template>
    </BtnContentToggle>

    <UButton
      class="w-full mt-4"
      :disabled="isInvalid"
      :loading="form.isLoading"
      native-type="submit"
    >
      {{ t('link-component.create-link') }}
    </UButton>
  </form>
</template>

<style lang="scss">
@import "./form-create.scss";
</style>
