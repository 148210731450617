<script setup lang="ts">
import { computed } from 'vue';
import { useLang } from '@/composables/useLang/useLang';
import Lang from '@/composables/useLang/types';
import { useI18n } from 'vue-i18n';

const { setLang } = useLang();
const { locale } = useI18n();

const changeLocale = async() => {
  if (locale.value === Lang.ru) {
    await setLang(Lang.en);
  } else {
    await setLang(Lang.ru);
  }
};

const buttonText = computed(() => locale.value === Lang.ru ? Lang.en : Lang.ru);
</script>

<template>
  <div class="language">
    <button
      id="button-language"
      class="language__button"
      @click="changeLocale"
    >
      {{ buttonText.toUpperCase() }}
    </button>
  </div>
</template>

<style lang="scss" scoped>
@import "./language.scss";
</style>
