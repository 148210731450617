<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useAuth } from '@/composables/useAuth';
import useUserStore from '@/store/user';

const { push } = useRouter();
const userStore = useUserStore();
const { t } = useI18n();

const user = computed(() => userStore.user);
const id = computed(() => userStore.user?.id);

const isModalOpen = ref(false);

const handleLogout = async () => {
  useAuth().logout();
  await push({ name: 'login' });
};
</script>

<template>
  <USelect class="user-menu">
    <template #header>
      <div class="header">
        <UserAvatar />
        <div
          v-if="user"
          class="header__role"
        >
          <UserRole :role="user.role" />
        </div>
      </div>
    </template>

    <template #options>
      <RouterLink
        class="select__button"
        :to="{name: 'dashboard-settings', params: {id: id}}"
      >
        <UIcon icon="eva:settings-outline" />
        {{ t('logout-component.account-settings') }}
      </RouterLink>

      <button
        class="select__button"
        @click="isModalOpen = true"
      >
        <UIcon icon="eva:arrow-circle-left-outline" />
        {{ t('logout') }}
      </button>
    </template>
  </USelect>
  <UModal
    :is-open="isModalOpen"
    @close="isModalOpen = false"
  >
    <template #header>
      {{ t('logout-component.sure') }}
    </template>

    <template #actions="{ close }">
      <UButton
        class="min-w-[100px] md:mb-0 shadow-sm"
        @click="handleLogout"
      >
        {{ t('logout') }}
      </UButton>
      <UButton
        class="min-w-[100px] md:mb-0 shadow-sm"
        type="light"
        @click="close"
      >
        {{ t('cancel') }}
      </UButton>
    </template>
  </UModal>
</template>

<style lang="scss">
@import "./user-menu.scss";
</style>
