import { defineStore } from 'pinia';
import { State } from '@/store/theme/types';

const isDark = Boolean(localStorage.getItem('cropped-is-dark'));

if (isDark) {
  document.body.className = 'dark';
} else {
  document.body.className = 'light';
}

const useThemeStore = defineStore('theme', {
  state: (): State => ({
    isDark,
  }),
  actions: {
    toggleTheme(this: State) {
      this.isDark = !this.isDark;
      if (this.isDark) {
        document.body.className = 'dark';
      } else {
        document.body.className = 'light';
      }

      localStorage.setItem('cropped-is-dark', this.isDark ? '1' : '');
    }
  },
});

export default useThemeStore;
