<template>
  <div class="app">
    <AppNavbar class="app__navbar" />

    <main class="app__main main">
      <slot />
    </main>

    <AppSidebar />
  </div>
</template>
