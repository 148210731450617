import i18n from '@/utils/i18n';
import { useNotification } from '@kyvg/vue3-notification';

export const useCopy = () => {
  const { notify } = useNotification();
  const { locale } = i18n.global;

  const messages = {
    en: {
      copied: 'Address is copied'
    },
    ru: {
      copied: 'Скопирован адрес'
    }
  };

  const baseURL = import.meta.env.VITE_BASE_URL_API;

  const copy = async (value: string, withBaseUrl = true) => {
    const url = withBaseUrl ? `${ baseURL }/` : '';
    const valueToCopy = url + value;

    await navigator.clipboard.writeText(valueToCopy);
    const message = messages[locale.value as keyof typeof messages].copied;

    notify({
      title: `${ message }: ${ valueToCopy }`,
      type: 'success',
    });
  };

  return {
    copy,
  };
};
