<script setup lang="ts">
interface _VTI_TYPE_RangeDate {
  start: _VTI_TYPE_Date | null
  end: _VTI_TYPE_Date | null
}
interface Props {
  modelValue?: _VTI_TYPE_Date | string | _VTI_TYPE_RangeDate
  startDate?: _VTI_TYPE_Date | string
  minDate?: _VTI_TYPE_Date | string
  isRange?: boolean
  placeholder?: string
  mode?: 'date' | 'dateTime'
}
import { computed, ref, WritableComputedRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { onClickOutside } from '@vueuse/core'
import { DatePicker } from 'v-calendar'
import { useDayjs } from '@/composables/useDayjs'
import { RangeDate } from './types'
import 'v-calendar/dist/style.css';
enum Period {
  all,
  today,
  week,
  month
}
const { t, locale } = useI18n();
const props = withDefaults(defineProps<Props>(), {
  mode: 'date',
  isRange: false,
});
const emit = defineEmits(['update:modelValue']);
const $dayjs = useDayjs();
const dateRange: WritableComputedRef<string | Date | RangeDate | undefined> = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    if (newValue) {
      // todo пофиксить дубль set на dateRange(возможно связазно с v-calendar)
      const isSameDate = JSON.stringify(newValue) === JSON.stringify(dateRange.value);
      if (!isSameDate) {
        let formattedDate = newValue;
        if (typeof newValue === 'object' && 'start' in newValue && 'end' in newValue) {
          // V-calendar меняет даты местами, если start > end
          // Но правила(пропс rules) применяет до смены местами
          // Из-за этого пришлось форматировать даты тут
          formattedDate = {
            start: $dayjs(newValue.start).startOf('d').toDate(),
            end: $dayjs(newValue.end).endOf('d').toDate()
          };
        }
        emit('update:modelValue', formattedDate);
      }
    }
  }
});
const calendar = ref(null);
const isOpened = ref<boolean>(false);
const openCalendar = () => {
  isOpened.value = true;
};
const closeCalendar = () => {
  isOpened.value = false;
};
const formatDateTime = (date: Date) => {
  const year = $dayjs(date).format('D MMM YYYY');
  const time = $dayjs(date).format('H:mm');
  return `${ year }\xA0${ t('calendar.at') }\xA0${ time }`;
};
const formatDate = (date: Date) => $dayjs(date).format('D MMM YYYY');
const getFormattedRange = (period: Period) => {
  switch (period) {
    case Period.today:
      return {
        start: $dayjs().startOf('date').toDate(),
        end: $dayjs().endOf('date').toDate(),
      };
    case Period.week:
      return {
        start: $dayjs().startOf('date').subtract(7, 'day').toDate(),
        end: $dayjs().endOf('date').toDate(),
      };
    case Period.month:
      return {
        start: $dayjs().startOf('date').subtract(1, 'month').toDate(),
        end: $dayjs().endOf('date').toDate(),
      };
    default:
      return {
        start: null,
        end: null,
      };
  }
};
const setFixedRange = (period: Period) => {
  closeCalendar();
  const newRange = getFormattedRange(period);
  emit('update:modelValue', newRange);
};
const currentDate = computed(() => {
  if (dateRange.value && typeof dateRange.value === 'object') {
    if (props.isRange) {
      const { start, end } = dateRange.value as RangeDate;
      if (start && end) {
        const formattedStart = formatDate(start);
        const formattedEnd = formatDate(end);
        return t(
          'calendar.from-to',
          {
            start: formattedStart,
            end: formattedEnd
          }
        ).replaceAll(' ', '\xA0');
      }
      return t('calendar.all-time');
    }
    if (props.mode === 'date') {
      return formatDate(dateRange.value as Date);
    }
    return formatDateTime(dateRange.value as Date);
  }
  return props.placeholder;
});
const clearDateRange = () => {
  setFixedRange(Period.all);
};
onClickOutside(calendar, () => {
  closeCalendar();
});
</script>

<template>
  <div
    ref="calendar"
    class="calendar"
  >
    <button
      type="button"
      class="calendar__button"
      @click="openCalendar"
    >
      <span class="calendar__text">
        {{ currentDate }}
      </span>

      <button
        v-if="
          isRange
            && dateRange
            && typeof dateRange === 'object'
            && !(dateRange instanceof Date)
            && dateRange.start
            && dateRange.end
        "
        @click.stop="clearDateRange"
      >
        <UIcon
          icon="pajamas:clear"
        />
      </button>

      <UIcon
        v-else
        icon="eva:calendar-outline"
      />
    </button>

    <Transition>
      <div
        v-show="isOpened"
        class="calendar__form"
      >
        <DatePicker
          v-model="
            // eslint-disable-next-line vue/valid-v-model Тип v-model для компонента не импортится из плагина
            (dateRange as any)
          "
          :start-date="startDate"
          :min-date="minDate"
          :mode="mode"
          :placeholder="props.placeholder ?? t('calendar.date')"
          :locale="locale"
          is24hr
          :is-range="isRange"
          :is-dark="false"
        />

        <div
          v-if="isRange"
          class="fixed-periods"
        >
          <button
            class="fixed-periods__button"
            @click="setFixedRange(Period.today)"
          >
            {{ t('calendar.today') }}
          </button>

          <div class="fixed-periods__divider" />

          <button
            class="fixed-periods__button"
            @click="setFixedRange(Period.week)"
          >
            {{ t('calendar.last-week') }}
          </button>

          <div class="fixed-periods__divider" />

          <button
            class="fixed-periods__button"
            @click="setFixedRange(Period.month)"
          >
            {{ t('calendar.last-month') }}
          </button>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss">
@import "./calendar.scss";

.v-enter-active,
.v-leave-active {
  @apply transition-all;
}

.v-enter-from,
.v-leave-to {
  @apply opacity-0;
}
</style>
