import Cookie from 'js-cookie';

// @ts-ignore
export default function auth({ next, router }) {
  if(!Cookie.get('token_session')) {
    return router.push({ name: 'login' });
  }

  return next();
}
