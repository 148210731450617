<script setup lang="ts">
interface Emits {
  // @ts-ignore
  (e: 'update:modelValue', val): void
}
import useGroupStore from '@/store/groups'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
const { t, locale } = useI18n();
const options = computed(() => {
  const name = computed(() => t('wo-group'));
  const o = [{ id: 0, name: name.value, value: null }];
  o.push(...useGroupStore().getGroups.map((group: any) => ({
    id: group.id,
    name: group.name,
    value: group.id,
  })));
  return o;
});
const emit = defineEmits<Emits>();
// const props = defineProps<Props>();
const selected = ref();
watch(selected, () => {
  emit('update:modelValue', selected.value);
});
</script>

<template>
  <USelect
    :key="locale + 'GroupList'"
    v-model="selected"
    :options="options"
    class="select-form shadow-lg"
  />
</template>

<style lang="scss">
.select-form {
  @apply text-sm font-bold mb-[10px] rounded-[10px] max-w-full;

  .select {
    &__header {
      @apply p-3;
    }

    &__body {
      @apply rounded-[10px];
    }
  }
}
</style>
