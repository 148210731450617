<script setup lang="ts">
import useGroupStore from '@/store/groups';
import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const form = reactive({
  data: {
    name: '',
  },
  pending: false,
});

const groups = useGroupStore();
const onSaveGroup = async () => {
  if (form.data.name) {
    form.pending = true;
    await groups.save(form.data);
    form.data.name = '';
    form.pending = false;
  }
};
</script>

<template>
  <div>
    <UInput
      v-model="form.data.name"
      :placeholder="t('new-group')"
      :on-save="onSaveGroup"
      @keydown.enter="onSaveGroup"
    />
  </div>
</template>
