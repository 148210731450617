<script setup lang="ts">
interface EmitTypes {
  // @ts-ignore
  (e: 'update:modelValue', val): void
  (e: 'onSave'): void
}
interface PropTypes {
  type?: 'text' | 'password' | 'email'
  modelValue?: string | number
  placeholder?: string
  passwordReveal?: boolean
  beforeText?: string
  generateText?: boolean
  generateTextOnmount?: boolean
  icon?: string
  disabled?: boolean
  error?: {
    hasError: boolean
    message?: string
  }
  onSave?: () => void
  autocomplete?: 'off' | 'new-password' | 'current-password' | 'email'
  name?: string
}
import { ref, onMounted } from 'vue'
const props = withDefaults(defineProps<PropTypes>(), {
  type: 'text',
  passwordReveal: false,
});
// Передача информации
const emit = defineEmits<EmitTypes>();
const value = ref(props.modelValue);
const onTyping = (e: any) => {
  value.value = e.target.value;
  emit('update:modelValue', value.value);
};
const currentType = ref(props.type);
const togglePassword = () => {
  if (currentType.value === 'password') {
    currentType.value = 'text';
    return;
  }
  currentType.value = 'password';
};
/**
 * Генерируем рандомную строку
 */
const generateRandomString = () => {
  value.value = (+new Date() * Math.random()).toString(36).substring(0, 5);
  emit('update:modelValue', value.value);
};
onMounted(() => {
  if(props.generateTextOnmount) {
    generateRandomString();
  }
});
</script>

<template>
  <div class="u-input">
    <span
      v-if="beforeText"
      class="u-input__before-text"
    >
      {{ beforeText }}
    </span>

    <UIcon
      v-if="props.icon"
      class="u-input__icon"
      :icon="props.icon"
    />

    <input
      :ref="props.name"
      class="u-input__control"
      :class="{
        'u-input__control--icon': icon,
        'u-input__control--pr': passwordReveal || generateText,
        'u-input__control--with-text': beforeText,
        'u-input__control--error': error?.hasError
      }"
      :name="props.name"
      :type="currentType"
      :placeholder="placeholder"
      :value="props.modelValue"
      :disabled="props.disabled"
      :autocomplete="props.autocomplete"
      @input="onTyping"
    >

    <button
      v-if="passwordReveal"
      type="button"
      class="u-input__password-reveal"
      @click="togglePassword"
    >
      <UIcon
        v-if="currentType === 'password'"
        icon="akar-icons:eye"
      />
      <UIcon
        v-else
        icon="akar-icons:eye-slashed"
      />
    </button>

    <div
      v-if="generateText"
      class="u-input__action"
    >
      <button
        type="button"
        class="text-black dark:text-white hover:text-primaryHover transition-all"
        @click="generateRandomString"
      >
        <UIcon icon="eva:flash-fill" />
      </button>
    </div>

    <div
      v-if="props.onSave"
      class="u-input__action"
    >
      <button
        type="button"
        class="text-black dark:!text-white hover:text-primaryHover transition-all"
        @click="props.onSave"
      >
        <UIcon icon="eva:save-outline" />
      </button>
    </div>
    <!-- /.u-input__action -->
    <span
      v-if="error?.hasError"
      class="u-input__error-message"
    >
      {{ error?.message }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
@import "./u-input.scss";
</style>
