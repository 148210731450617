<script lang="ts">
import { defineComponent, ref } from 'vue';
import type { PropType } from 'vue';
import { Icon } from '@iconify/vue';

export default defineComponent({
  name: 'BtnContentToggle',

  components: { Icon },

  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const isActive = ref<boolean>(props.modelValue);

    function toggleActive() {
      isActive.value = !isActive.value;
      emit('update:modelValue', isActive.value);
    }

    return {
      isActive,
      toggleActive,
    };
  },
});
</script>

<template>
  <div class="btn-content-toggle">
    <Transition name="fadeIn">
      <button
        v-show="!modelValue"
        class="btn-content-toggle__show"
        type="button"
        @click="toggleActive"
      >
        <slot />
        <Icon
          icon="material-symbols:chevron-right-rounded"
          class="text-sm -mt-[2px] ml-1"
        />
      </button>
    </Transition>

    <Transition name="fadeIn">
      <div
        v-show="modelValue"
        class="w-full"
      >
        <slot name="content" />

        <button
          class="btn-content-toggle__hide"
          type="button"
          @click="toggleActive"
        >
          <Icon icon="material-symbols:close-rounded" />
        </button>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
@import "./btn-content-toggle.scss";

.fadeIn-enter-active,
.fadeIn-leave-active {
  transition: all 150ms ease-out;
}

.fadeIn-enter-from,
.fadeIn-leave-to {
  transform: translateY(-15px);
  opacity: 0;
}
</style>
