import { defineStore } from 'pinia';
import { Roles, User } from '@/types/models/User';
import { useAxios } from '@/composables/useAxios';
import { State } from '@/store/user/types';
import { useNotification } from '@kyvg/vue3-notification';

const { notify } = useNotification();

const useUserStore = defineStore('user', {
  state: (): State => ({
    user: null,
    isLoading: false,
    isSubmitting: false,
  }),

  actions: {
    setMe(payload: User) {
      this.user = payload;
    },

    fetchMe() {
      const $axios = useAxios();

      return new Promise((resolve, reject) => {
        this.isLoading = true;
        $axios.get('/api/user')
          .then(({ data }) => {
            this.user = data.data;
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },

    updateMe(formData: FormData) {
      const $axios = useAxios();

      return new Promise((resolve, reject): void => {
        this.isSubmitting = true;
        $axios.post(`/api/users/${ this.user?.id }`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(({ data }) => {
            this.user = data.data;
            resolve(data.data);
            notify({
              title: data.message,
              type: 'success'
            });
          })
          .catch((err) => {
            reject(err);
            notify({
              title: `${ err.response?.data.message } #${ err.response?.data.status ?? '500' }`,
              type: 'error'
            });
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      });
    }
  },

  getters: {
    getUser: (state: State) => state.user,
    isManager: (state: State) => state.user?.role === Roles.user,
    isAdmin: (state: State) => state.user?.role === Roles.admin,
    isLeadManager: (state: State) => state.user?.role === Roles.leadManager,
  },
});

export default useUserStore;
